import { AbstractControl, ValidationErrors } from '@angular/forms';
import { v4, v7, validate } from 'uuid'
import { CustomFieldSelection } from '../entities/household';
import { formatCurrency } from '@angular/common';

const ItemDoesNotExistIndex = -1;
const oneHundred = 100;
const two = 2;
const ten = 10;
export const one = 1;
export const newItemPrefix = "new";
export const zero = 0;
export const jointTotalDivider = 2;

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || typeof value === "undefined";
}

/**
  *  Adds the items in newItems that are not in list to list. Replaces the items in list with the corresponding items in newItems.
  * Returns list.
  * @param list The list to be updated.
  * @param equalityComparer A function to determine equality between items of type T.
  * @param newItems A list containing the items to be added to list/to replace the corresponding items in list.
  */
export function replaceOrAdd<T>(list: T[], equalityComparer: (newItem: T, existingItem: T) => boolean, newItems: T[]): T[] {
  for (const newItem of newItems) {
    const index = list.findIndex(x => equalityComparer(newItem, x))
    if (index === ItemDoesNotExistIndex) {
      list.push(newItem);
    } else {
      list[index] = newItem;
    }
  }
  return list;
}

export function percentConverter(number: number, action: "percentToDecimal" | "decimalToPercent", displayDecimalPoints: number): number {
  // This logic was copied from the AOS mw-number.component.ts.
  switch (action) {
    case "decimalToPercent":
      return Number((number * oneHundred).toFixed(displayDecimalPoints));
    case "percentToDecimal":
      return Number((number / oneHundred).toFixed(displayDecimalPoints + two));
    default:
      return throwError(`action has an invalid value: ${typeof action}`);
  }
}

export function asPercent(number: number | null | undefined, displayDecimalPoints: number): number | null | undefined {
  if (isNullOrUndefined(number)) {
    return number;
  }

  return percentConverter(number, "decimalToPercent", displayDecimalPoints);
}

export function asDecimal(number: number, displayDecimalPoints: number): number
export function asDecimal(number: number | null, displayDecimalPoints: number): number | null
export function asDecimal(number: number | undefined | null, displayDecimalPoints: number): number | undefined | null
export function asDecimal(number: number | null | undefined, displayDecimalPoints: number): number | null | undefined {
  if (isNullOrUndefined(number)) {
    return number;
  }

  return percentConverter(number, "percentToDecimal", displayDecimalPoints);
}

export function throwError(errorMessage: string): never {
  throw new Error(errorMessage);
}

/**
  * Returns a date that has the same date as the passed in date except that the timezone is changed to UTC and the time is set to 12 am.
  */
export function forceDateToMidnightUtc(date: string | Date): Date
export function forceDateToMidnightUtc(date: string | Date | null): Date | null
export function forceDateToMidnightUtc(date: string | Date | null | undefined): Date | null | undefined
export function forceDateToMidnightUtc(date: string | Date | null | undefined): Date | null | undefined {
  if (isNullOrUndefined(date)) {
    return date;
  }
  const newDate = new Date(date);
  return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
}

export function getBmi(weight: number, height: number): number
export function getBmi(weight: number | null | undefined, height: number | null | undefined): number | null
export function getBmi(weight: number | null | undefined, height: number | null | undefined): number | null {
  if (isNullOrUndefined(weight) || isNullOrUndefined(height)) {
    return null;
  }

  return round(weight / Math.pow((height / oneHundred), two), one);
}

export function round(num: number, places: number) {
  const factor = ten ** places;
  return Math.round(num * factor) / factor;
}

export function createGuid(useSequentialGuid = false): string {
  if (useSequentialGuid) {
    return v7()
  }

  return v4();
}

export function isGuid(potentialGuid: string | null) {
  if (isNullOrUndefined(potentialGuid)) {
    return false;
  }

  return validate(potentialGuid);
}

export function getYesNoString(value?: string | null): string {
  if (value === "true") return "Yes";

  return "No";
}

export function getBooleanString(value?: boolean | null): string {
  if (value) return "Yes";

  return "No";
}

export function getCurrency(value: number | null): string {
  if (!value) return "$0";

  return formatCurrency(value, "en-AU", "$", "AUD");
}

export const phoneNumberRegExp = "[0-9]+"

export function getNewItemGuid() {
  // We use a sequential guid for the new item GUID because it makes sorting by the ID effectively sort by the item creation date, which is 
  // the order in which we want the items to display in the UI.
  // Unfortunately we can't sort by the Created field because that field is reset ever time an item is edited...
  return `${newItemPrefix}*${createGuid(true)}`;
}

export function mapJourneyIdToDtoId(id: string): number | null {
  if (id.startsWith(newItemPrefix)) {
    return null;
  }

  if (!isInteger(id)) {
    return throwError(`The id: "${id}" is not a valid integer.`);
  }

  return Number(id);
}

function isInteger(possibleInt: string) {
  return possibleInt.match(/^[0-9]+$/u);
}

export function replaceTagsInNote(text: string): string {
  return text
    .replaceAll(" <u>", "&#160;&#160;<u style=\"text-decoration: underline;\">")
    .replaceAll(" <del>", "&#160;&#160;<del style=\"text-decoration: line-through;\">")
    .replaceAll("<u>", "<u style=\"text-decoration: underline;\">")
    .replaceAll("<del>", "<del style=\"text-decoration: line-through;\">");
}

export function firstOrNull<T>(items: T[], selectionPredicate: (item: T) => boolean = () => true): T | null {
  return items.find(selectionPredicate) ?? null;
}

export function getCustomFieldSelectionSingle(customFieldSelection: CustomFieldSelection[], customFieldId: number) {
  const customField = customFieldSelection.find(x => x.customFieldID === customFieldId);
  return isNullOrUndefined(customField) ? null : firstOrNull(customField.selectedFieldItems);
}

export function convertStringToNullableDate(possibleDate: string | Date | null | undefined) {
  if (isNullOrUndefined(possibleDate)) {
    return null;
  }
  return new Date(possibleDate);
}

export function ensureHasValue<T>(value: T | null | undefined, errorMessage?: string): T {
  if (isNullOrUndefined(value)) {
    throw new Error(`The value is null/undefined but must have a value: ${errorMessage ?? ''}`);
  }

  return value;
}

/**
  *  
  * Returns the items in list with duplicate values removed.
  * The original list is unmodified.
  * @param list The list from which duplicates are to be removed.
  */
export function unique<T>(list: T[]): T[] {
  return [...new Set(list)];
}

/**
  *  
  * Returns the items in list with duplicate values removed.
  * The original list is unmodified.
  * @param list The list from which duplicates are to be removed.
  */
export function uniqueBy<T, V>(list: T[], propertySelector: (object: T) => V): T[] {
  const set = new Set<V>();
  const newList: T[] = [];
  for (const item of list) {

    if (!set.has(propertySelector(item))) {
      set.add(propertySelector(item));
      newList.push(item);
    }
  }
  return newList;
}


/**
  *  
  * Returns the first item from the list. If there is not exactly 1 item in the list, the function throws an exception.
  * @param list The list from which the first and only item is to be returned.
  */
export function Single<T>(list: T[]): T {
  if (isNullOrUndefined(list)) {
    throw new Error("The parameter list is null/undefined.")
  }

  if (list.length !== one) {
    throw new Error(`The list must contain exactly 1 element. It contains ${list.length} element(s).`)
  }

  return list[0];
}

export function validateNumberPrecision(precision: number, range: number, value: number) {
  return Math.abs(round(value, range)) < Math.pow(ten, precision - range)
}

export function validateSqlServerDecimal(precision: number, range: number) {
  return (control: AbstractControl<number>): ValidationErrors | null => {
    if (isNullOrUndefined(control.value)) {
      return control.value;
    }

    const valid = validateNumberPrecision(precision, range, control.value);
    return valid ? null : { value: control.value }
  };
}